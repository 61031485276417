import styles from "./Offdays.module.css";

import { useTranslation } from "react-i18next";

import WorkshopTitle from "../../Components/Title/Title";

import WorkshopGlobal from "./sections/Global";

import WorkshopIndividual from "./sections/Individual";


export default function WorkshopOffdays () {


    const { t } = useTranslation();


    return (


        <div className={ styles.container }>

            <WorkshopTitle 
            
                title={ t ( "Gobal Off-Days" ) } 
            
                description={ t ( "global_offday_description" ) } 
                
            />

            <WorkshopGlobal />

            <WorkshopTitle 
            
                title={ t ( "Service Box Off-Days" ) } 
            
                description={ t ( "individual_offday_description" ) } 
                
            />

            <WorkshopIndividual />

        </div>


    );


};