// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Offdays_container__m8zQv > *:not(:last-child) {\n    margin-bottom: 2rem;\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Workshop/Contents/Offdays/Offdays.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB","sourcesContent":[".container > *:not(:last-child) {\n    margin-bottom: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Offdays_container__m8zQv"
};
export default ___CSS_LOADER_EXPORT___;
