/** @import @module */

import { gql } from "@apollo/client";

const 

/** @name Products */

PRODUCTS = gql`query productsQuery ( 
    
    $first: Int, 
    
    $last: Int, 
    
    $before: String, 
    
    $after: String,
    
    $productType: String!

    $sortOrder: String

    $width: [Float!]

    $height: [Float!]

    $diameter: [String!]

    $speedRating: [String!]

    $noiseIndex: [String!]

    $model: [String!]

    $radius: [Float!]

    $offset: [Int!]

    $pitchCircleDiameter: [String!]

    $manufacturer: [String!]

    $season: [String!]

    $loadIndex: [String!]

    $fuelEfficiency: [String!]

    $wetBreakingEfficiency: [String!]

    $noiseLevel: [String!]

    $vehicleType: [String!]

    $specifications: [String!]

    $voltage: [String!]

    $current: [String!]

    $capacity: [String!]

    $vendorId: [Int!]

    $studs: [StudsEnum!]

    $year: [Int!]

    $scraper: String

    $runFlat: Boolean,

    $ean: String,

    $id: Int,

    $showOnlyAvailable: Boolean,

    $stockQtysFrom: Int,

    $stringSearch: String,

    $availability: [AvailabilityTypeEnum!]

    $sortParams: AdminTableColumnSortingInput ) {

    adminMultiFieldSearch ( first: $first, last: $last, before: $before, after: $after, adminMultiFieldSearchInput: { 
        
        productType: $productType,

        sortOrder: $sortOrder,

        width: $width,

        height: $height,

        diameter: $diameter,

        speedRating: $speedRating,

        noiseIndex: $noiseIndex,

        model: $model,

        radius: $radius,

        offset: $offset,

        pitchCircleDiameter: $pitchCircleDiameter,

        manufacturer: $manufacturer,

        season: $season,

        loadIndex: $loadIndex,

        fuelEfficiency: $fuelEfficiency,

        wetBreakingEfficiency: $wetBreakingEfficiency,

        noiseLevel: $noiseLevel,

        vehicleType: $vehicleType,

        specifications: $specifications,

        voltage: $voltage,

        current: $current,

        capacity: $capacity,

        vendorId: $vendorId,

        studs: $studs,

        year: $year,

        runFlat: $runFlat,

        scraper: $scraper,

        ean: $ean,

        id: $id,

        showOnlyAvailable: $showOnlyAvailable,

        stockQtysFrom: $stockQtysFrom,

        sortParams: $sortParams,

        availability: $availability,

        stringSearch: $stringSearch
    
    } ) {

        edges {

            node {

                awsImageUrl

                boltsIncluded

                capacity

                centreBore

                createdAt

                current

                currentImage

                dedicatedAxle

                deleted

                description

                diameter

                ean

                freightClass

                fuelEfficiency

                hashId

                height

                id

                image

                imageCrop

                length

                loadIndex

                manufacturer {

                    id

                    name

                    visible

                }

                manufacturerId

                model

                noiseIndex

                noiseLevel

                offset

                originalImageUrl

                pitchCircleDiameter

                priceList

                prices {
                        
                    discountHasCoupon
                    
                    discountId
                    
                    discountValue
                    
                    id
                    
                    markupId
                    
                    markupValue

                    price

                    priceGroupA

                    priceGroupB

                    priceGroupC

                    originalPrice

                }

                productType

                productVendors {

                    id

                    vendorId

                    price

                    qty

                    qtyDays

                    qtyHour

                    qtyWeeks

                }

                radius

                roadSurface

                scraper

                season

                specifications

                speedRating

                studs

                uid

                updatedAt

                url

                usage

                used

                vehicleType

                vendors {

                    addPvn

                    createdAt

                    deleted

                    hashId

                    id

                    name

                    visible

                }
                
                viewCount
                
                viewUniqCount
                
                visible
                
                voltage
                
                wetBreakingEfficiency
                
                width
                
                year

                runFlat

                isCTire

                centreBore

                color

            }

        }

        pageInfo { 
            
            hasNextPage 
            
            hasPreviousPage 
            
            startCursor 
            
            endCursor 
        
        }

    }

}`,

/** @name SearchAttributes */

SEARCH_ATTRIBUTES = gql`query { searchAttributes }`,

/** @name Product (COMPLETE DATA) */

PRODUCT_MAX = gql`query getProductMax ( $id: [ID!]! ) { product ( id: $id ) {

    edges {

        node {

            model

            manufacturer {

                id

                name

            }

            width

            height

            diameter

            capacity

            current

            voltage

            pitchCircleDiameter

            centreBore

            boltsIncluded

            dedicatedAxle

            freightClass

            fuelEfficiency

            radius

            length

            loadIndex

            noiseIndex

            noiseLevel

            offset

            productType

            productVendors {

                id

                price

                productId

                qty

                qtyDays

                qtyHour

                qtyWeeks

                vendorId

            }

            roadSurface

            season

            speedRating

            studs

            usage

            used

            vehicleType

            vendors {

                id

                name

            }

            wetBreakingEfficiency

            year

            specifications

            runFlat

            isCTire

        }

    }

} }`,

/** @name Product (COMPLETE DATA) */

PRODUCT = gql`query getProduct ( $id: ID! ) { adminProduct ( id: $id ) {

    awsImageUrl

    boltsIncluded

    capacity

    centreBore

    createdAt

    current

    currentImage

    dedicatedAxle

    deleted

    description

    diameter

    ean

    freightClass

    fuelEfficiency

    hashId

    height

    id

    image

    imageCrop

    length

    loadIndex

    manufacturer {

        id

        name

        visible

    }

    manufacturerId

    model

    noiseIndex

    noiseLevel

    offset

    originalImageUrl

    pitchCircleDiameter

    priceList

    prices {
            
        discountHasCoupon
        
        discountId
        
        discountValue
        
        id
        
        markupId
        
        markupValue

    }

    productType

    productVendors {

        id

        vendorId

        price

        qty

        qtyDays

        qtyHour

        qtyWeeks

    }

    radius

    roadSurface

    scraper

    season

    specifications

    speedRating

    studs

    uid

    updatedAt

    url

    usage

    used

    vehicleType

    vendors {

        addPvn

        createdAt

        deleted

        hashId

        id

        name

        visible

    }
    
    viewCount
    
    viewUniqCount
    
    visible
    
    voltage
    
    wetBreakingEfficiency
    
    width
    
    year

    runFlat

    isCTire

} }`,

/** @name PRODUCT_PREVIEW (COMPLETE DATA) */

PRODUCT_PREVIEW = gql`query getProductPreview ( $id: ID! ) { adminProduct ( id: $id ) {

    model

    manufacturer {

        id

        name

    }

    width

    height

    diameter

    capacity

    current

    voltage

    pitchCircleDiameter

    centreBore

    boltsIncluded

    dedicatedAxle

    freightClass

    fuelEfficiency

    radius

    length

    loadIndex

    noiseIndex

    noiseLevel

    offset

    productType

    productVendors {

        id

        price

        productId

        qty

        qtyDays

        qtyHour

        qtyWeeks

        vendorId

    }

    roadSurface

    season

    speedRating

    studs

    usage

    used

    vehicleType

    vendors {

        id

        name

    }

    wetBreakingEfficiency

    year

    specifications

    runFlat

    isCTire

} }`,

/** @name PRODUCT_IMPORTS */

PRODUCT_IMPORTS = gql`query { adminImports }`,

/** @name PRODUCT_TYPE_FIELDS */

PRODUCT_TYPE_FIELDS = gql`query {

    __type ( name: "Product" ) {

        fields {

            name

        }

    }

}`,

/** @name PRODUCT_INPUT_FIELDS */

PRODUCT_INPUT_FIELDS = gql`query {

    __type ( name: "AdminMultiFieldSearchInput" ) {
    
    	inputFields {
        
            name
        
    	}

    }

}`,

/** @name PRODUCT_TYPES_ENUM */

PRODUCT_TYPES_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "ProductTypesEnum" ) {

        enumValues {

            name

        }

    }

}`,

/** @name STUDS_TYPES_ENUM */

STUDS_TYPES_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "StudsEnum" ) {

        enumValues {

            name

        }

    }

}`,

/** @name PRODUCT_AVAILABILITY_ENUM */

PRODUCT_AVAILABILITY_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "AvailabilityTypeEnum" ) {

        enumValues {

            name

        }

    }

}`;

/** @exports Queries */

export {

    PRODUCTS,

    PRODUCT,

    SEARCH_ATTRIBUTES,

    PRODUCT_MAX,

    PRODUCT_PREVIEW,

    PRODUCT_TYPE_FIELDS,

    PRODUCT_INPUT_FIELDS,

    PRODUCT_TYPES_ENUM,

    PRODUCT_IMPORTS,

    STUDS_TYPES_ENUM, 

    PRODUCT_AVAILABILITY_ENUM

}