// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Title_container__tBuwF {\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: wrap;\n\n    gap: 1rem;\n\n    align-items: center;\n\n}\n\n.Title_content__y7Yc- {\n\n    flex: 1 1;\n\n}\n\n.Title_content__y7Yc- p {\n\n    margin: 0;\n\n}\n\n.Title_content__y7Yc- > *:not(:last-child) {\n\n    margin-bottom: 0.5rem;\n\n}\n\n.Title_description__NGAoh {\n\n    font-size: 0.875rem;\n      \n}\n\n.Title_title__AUxch {\n\n    color: var( --typography-hover-color );\n\n    font-weight: 600;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Workshop/Components/Title/Title.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;;IAEb,mBAAmB;;IAEnB,eAAe;;IAEf,SAAS;;IAET,mBAAmB;;AAEvB;;AAEA;;IAEI,SAAO;;AAEX;;AAEA;;IAEI,SAAS;;AAEb;;AAEA;;IAEI,qBAAqB;;AAEzB;;AAEA;;IAEI,mBAAmB;;AAEvB;;AAEA;;IAEI,sCAAsC;;IAEtC,gBAAgB;;AAEpB","sourcesContent":[".container {\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: wrap;\n\n    gap: 1rem;\n\n    align-items: center;\n\n}\n\n.content {\n\n    flex: 1;\n\n}\n\n.content p {\n\n    margin: 0;\n\n}\n\n.content > *:not(:last-child) {\n\n    margin-bottom: 0.5rem;\n\n}\n\n.description {\n\n    font-size: 0.875rem;\n      \n}\n\n.title {\n\n    color: var( --typography-hover-color );\n\n    font-weight: 600;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Title_container__tBuwF",
	"content": "Title_content__y7Yc-",
	"description": "Title_description__NGAoh",
	"title": "Title_title__AUxch"
};
export default ___CSS_LOADER_EXPORT___;
