// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GlobalItem_container__Xk9DJ {\n\n    background-color: var(--container-color);\n\n    padding: 1rem;\n\n    border-radius: 0.3rem;\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: wrap;\n\n    gap: 1rem;\n\n    align-items: center;\n\n    justify-content: space-between;\n\n}\n\n.GlobalItem_action__bIado {\n\n    flex: 1 1;\n\n    display: flex;\n\n    justify-content: flex-end;\n\n    gap: 1rem;\n\n}\n\n.GlobalItem_delete__U3KxC {\n\n    background-color: #F44336;\n\n}\n\n.GlobalItem_delete__U3KxC:hover {\n\n    background-color: #b71c1c;\n\n}\n\n.GlobalItem_toggle__756xX {\n\n    display: inline-block;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Workshop/Contents/Offdays/sections/GlobalItem.module.css"],"names":[],"mappings":"AAAA;;IAEI,wCAAwC;;IAExC,aAAa;;IAEb,qBAAqB;;IAErB,aAAa;;IAEb,mBAAmB;;IAEnB,eAAe;;IAEf,SAAS;;IAET,mBAAmB;;IAEnB,8BAA8B;;AAElC;;AAEA;;IAEI,SAAO;;IAEP,aAAa;;IAEb,yBAAyB;;IAEzB,SAAS;;AAEb;;AAEA;;IAEI,yBAAyB;;AAE7B;;AAEA;;IAEI,yBAAyB;;AAE7B;;AAEA;;IAEI,qBAAqB;;AAEzB","sourcesContent":[".container {\n\n    background-color: var(--container-color);\n\n    padding: 1rem;\n\n    border-radius: 0.3rem;\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: wrap;\n\n    gap: 1rem;\n\n    align-items: center;\n\n    justify-content: space-between;\n\n}\n\n.action {\n\n    flex: 1;\n\n    display: flex;\n\n    justify-content: flex-end;\n\n    gap: 1rem;\n\n}\n\n.delete {\n\n    background-color: #F44336;\n\n}\n\n.delete:hover {\n\n    background-color: #b71c1c;\n\n}\n\n.toggle {\n\n    display: inline-block;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GlobalItem_container__Xk9DJ",
	"action": "GlobalItem_action__bIado",
	"delete": "GlobalItem_delete__U3KxC",
	"toggle": "GlobalItem_toggle__756xX"
};
export default ___CSS_LOADER_EXPORT___;
