import styles from "./Workshop.module.css";

import { useLocation } from "react-router-dom";

import { useQuery } from "@apollo/client";

import { GET_WORKSHOPS } from "../../Queries/Workshop";

import Breadcrumbs from "../../Components/Dashboard/Breadcrumbs";

// import WorkshopTimeTable from "../../Components/Dashboard/Workshop/Table";

import ComponentLoader from "../../Components/Dashboard/Loaders/ComponentLoader";

import WorkshopTabs from "../../Components/Dashboard/Workshop/Components/Tabs/Tabs";

import WorkshopHours from "../../Components/Dashboard/Workshop/Contents/WorkingHours/WorkingHours";

import WorkshopBoxes from "../../Components/Dashboard/Workshop/Contents/Boxes/Boxes";

import WorkshopOffdays from "../../Components/Dashboard/Workshop/Contents/Offdays/Offdays";


export default function Workshop () {

    
    const location = useLocation();

    const currentTab = new URLSearchParams(location.search).get('tab');


    return (
        <section className={ styles.container }>
            <Breadcrumbs />
            <WorkshopTabs />
            {
                currentTab === 'working-hours' ? <WorkshopHours /> : 
                currentTab === 'boxes' ? <WorkshopBoxes /> : 
                currentTab === 'off-days' ? <WorkshopOffdays /> : null
            }
        </section>
    );


};