import styles from "./Global.module.css";

import { useTranslation } from "react-i18next";

import { useState } from "react";

import { useQuery } from "@apollo/client";

import { useMutation } from "@apollo/client";

import { GET_GLOBAL_OFFDAYS } from "../../../../../../Queries/Workshop";

import { CREATE_OFFDAY } from "../../../../../../Mutations/Workshop";

import ComponentLoader from "../../../../Loaders/ComponentLoader";

import GlobalWorkshopItem from "./GlobalItem";

import Button from "../../../../Global/Forms/Button";

import { Input } from "../../../../Global/Forms/Inputs_Spread";


export default function WorkshopGlobal () {


    const { t } = useTranslation();

    const { data, loading, error, refetch } = useQuery ( GET_GLOBAL_OFFDAYS, { fetchPolicy: "network-only" } );

    const [ offday, setOffday ] = useState ( "" );

    const [ createOffday, { loading: loading_create } ] = useMutation ( CREATE_OFFDAY );

    if ( loading ) return <ComponentLoader />;

    const handleCreate = async () => {

        try {

            await createOffday ({ variables: { offDate: offday } });

            setOffday ( "" );

            refetch ();

        } catch ( error ) {

            console.log ( error );

        }

    };


    return (

        <div className={ styles.container }>


            <div className={ styles.content }>

                {

                    data?.adminOffDay?.map ( ( offday, index ) =>

                        <GlobalWorkshopItem data={ offday } key={ offday?.id || index } refetch={ refetch } />

                    )

                }
                
            </div>


            <div className={ styles.action }>

                <Input type="date" placeholder="Add offday" value={ offday } onChange={ (e) => setOffday ( e.target.value ) } />

                <Button onClick={ handleCreate } text="Add" disabled={ loading_create } />
                
            </div>


        </div>

    );


};