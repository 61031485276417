import styles from "./WorkingHours.module.css";
import { useTranslation } from "react-i18next";
import { Input } from "../../../Global/Forms/Inputs_Spread";
import Checkbox from "../../../Global/Forms/Checkbox";

export default function WorkingHoursItem({ name, start, end, status, loading, update, loading_save }) {

    const { t } = useTranslation();
    const handleTimeChange = (timeType) => (e) => {
        update(prev => ({ ...prev, [`${timeType}${name}`]: e.target.value }));
    };

    const handleStatusChange = (e) => {
        update(prev => ({
            ...prev,
            [`${name.toLowerCase()}Enabled`]: e.target.checked,
            [`startTime${name}`]: "00:00",
            [`endTime${name}`]: "00:00"
        }));
    };

    const isDisabled = loading || status || loading_save;

    return (
        <div className={styles.item}>
            <div className={styles.itemName}>{ t ( name ) }</div>
            <div className={styles.itemContent}>
                <div className={styles.itemForm}>
                    <Input
                        type="time"
                        value={start}
                        disabled={isDisabled}
                        onChange={handleTimeChange('startTime')}
                    />
                    -
                    <Input
                        type="time"
                        value={end}
                        disabled={isDisabled}
                        onChange={handleTimeChange('endTime')}
                    />
                </div>
                <Checkbox
                    text={ t ( "Free" ) }
                    id={name}
                    state={status}
                    disabled={loading || loading_save}
                    action={handleStatusChange}
                />
            </div>
        </div>
    );
}