import styles from "./Boxes.module.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_WORKSHOP_BOXES } from "../../../../../Queries/Workshop";
import { CREATE_BOX } from "../../../../../Mutations/Workshop";

import ServiceBox from "./Box";

import { Input } from "../../../Global/Forms/Inputs_Spread";

import Toggle from "../../../Global/Forms/Toggle/Toggle";

import ComponentLoader from "../../../Loaders/ComponentLoader";
import Button from "../../../Global/Forms/Button";
import Checkbox from "../../../Global/Forms/Checkbox";

export default function WorkshopBoxes () {

    const { t } = useTranslation();

    const { data, loading, error, refetch } = useQuery(GET_WORKSHOP_BOXES, { fetchPolicy: "network-only" });

    const [ boxName, setBoxName ] = useState("");

    const [ createBox, { loading: loading_create } ] = useMutation(CREATE_BOX);

    const handleCreate = async () => {

        try {

            await createBox({ variables: { boxName, active: true } });

            setBoxName("");

            refetch();

        } catch ( error ) {

            console.log ( error );

        }

    };

    if ( loading ) return <ComponentLoader />;

    return (

        <div className={ styles.container }>

            <div className={ styles.content }>
                
                {

                    data?.adminServiceBoxes?.map ( ( serviceBox, index ) => 
                    
                        <ServiceBox serviceBox={ serviceBox } key={ serviceBox?.id || index } refetch={refetch} />
                    
                    )

                }
                
            </div>

            <div className={ styles.action }>
                
                <Input placeholder={ t ( "Box Name" ) } value={ boxName } onChange={ (e) => setBoxName(e.target.value) } disabled={ loading_create } />

                <Button text={ "Add Box" } onClick={ handleCreate } disabled={ loading_create } />
                
            </div>

        </div>

    );

};