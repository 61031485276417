import { gql } from "@apollo/client";

export const GET_WORKSHOP_HOURS = gql`

    query {
        adminWorkingHours {
            id
            startTimeMonday
            endTimeMonday
            startTimeTuesday
            endTimeTuesday
            startTimeWednesday
            endTimeWednesday
            startTimeThursday
            endTimeThursday
            startTimeFriday
            endTimeFriday
            startTimeSaturday
            endTimeSaturday
            startTimeSunday
            endTimeSunday
        }
    }

`;

export const GET_WORKSHOP_BOXES = gql`

    query {
    
        adminServiceBoxes {
            id
            boxName
            active
        }
    
    }

`;

export const GET_GLOBAL_OFFDAYS = gql`

    query {
        adminOffDay {
            id
            offDate
        }
    }

`;

export const GET_SERVICE_BOX_OFFDAYS = gql`

    query {

        adminSerivceBoxOffDay {
            id
            date
            serviceBoxId
        }

    }

`;