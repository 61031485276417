import styles from "./Individual.module.css";

import { useState } from "react";

import { useQuery, useMutation } from "@apollo/client";

import { CREATE_SERVICE_BOX_OFFDAY } from "../../../../../../Mutations/Workshop";

import { GET_SERVICE_BOX_OFFDAYS } from "../../../../../../Queries/Workshop";

import { GET_WORKSHOP_BOXES } from "../../../../../../Queries/Workshop";

import ComponentLoader from "../../../../Loaders/ComponentLoader";

import IndividualWorkshopItem from "./IndividualItem";

import SelectSpread from "../../../../Global/Forms/Select_Spread";

import { Input } from "../../../../Global/Forms/Inputs_Spread";

import Button from "../../../../Global/Forms/Button";


export default function WorkshopIndividual () {


    const { data: offdays, loading: loading_offdays, error: error_offdays, refetch } = useQuery ( GET_SERVICE_BOX_OFFDAYS, { fetchPolicy: "network-only" } );

    const { data: boxes, loading: loading_boxes, error: error_boxes } = useQuery ( GET_WORKSHOP_BOXES, { fetchPolicy: "network-only" } );

    const [ formData, setFormData ] = useState ({

        serviceBoxId: "",

        offDate: ""

    });

    const [ createOffday, { loading: loading_create } ] = useMutation ( CREATE_SERVICE_BOX_OFFDAY );

    const handleCreate = async () => {

        try {

            await createOffday ({ 
                variables: { 
                    serviceBoxId: parseInt(formData.serviceBoxId), // Convert to integer
                    date: new Date(formData.offDate).toISOString() // Convert to ISO8601DateTime
                } 
            });

            setFormData ({ serviceBoxId: "", offDate: "" });

            refetch ();

        } catch ( error ) {

            console.log ( error );

        }

    };
    

    if ( loading_offdays || loading_boxes ) return <ComponentLoader />;


    return (

        <div className={ styles.container }>
        
        
            <div className={ styles.content }>

                {

                    offdays?.adminSerivceBoxOffDay?.map ( ( offday, index ) =>

                        <IndividualWorkshopItem 
                        
                            data={ offday } 
                        
                            serviceBox={ boxes?.adminServiceBoxes.filter ( box => parseInt ( box.id ) === parseInt ( offday?.serviceBoxId ) )[0]?.boxName }
                        
                            key={ offday?.id || index } 
                            
                            refetch={ refetch } />

                    ) 

                }
                
            </div>


            <div className={ styles.action }>

                <SelectSpread

                    def={ true }

                    options={ boxes?.adminServiceBoxes?.map ( box => ({ value: box.id, name: box.boxName }) ) }

                    value={ formData.serviceBoxId }

                    action={ e => setFormData ({ ...formData, serviceBoxId: e.target.value }) } />

                <Input

                    type="date"

                    placeholder="Add offday"

                    value={ formData.offDate }

                    onChange={ e => setFormData ({ ...formData, offDate: e.target.value }) } />



                <Button text="Add" onClick={ handleCreate } disabled={ loading_create } />


                
            </div>


        </div>

    );


};