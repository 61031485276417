import styles from "./Styles.module.css";

import { useTranslation } from "react-i18next";

import { useEffect, useState, useRef } from "react";

import { useQuery } from "@apollo/client";

import Fieldset from "../../Global/Forms/Fieldset";

import { Input } from "../../Global/Forms/Inputs_Spread";

import { ORDER_CUSTOMERS } from "../../../../Queries/Orders";

import CenterLoader from "../../Loaders/CenterLoader";

export default function CustomerSearch ( { state, update } ) { const 

    { t : __ } = useTranslation (),

    [ search, setSearch ] = useState ( "" ),

    [ searchString, setSearchString ] = useState ( "" ),

    [ expanded, setExpanded ] = useState ( false ),

    [ dataList, setDataList ] = useState ( [] ),

    { loading, refetch } = useQuery ( ORDER_CUSTOMERS, { variables : { page: 0 }, notifyOnNetworkStatusChange: true, onCompleted: res => { setDataList ( res ) } } ),

    detector = useRef ( null ),

    /**
     * 
     * @event onSearch
     * 
     * @description Handles the search event.
     * 
     * @param { Object } e - The event object.
     * 
     * @returns { void }
     * 
     */

    onSearch = e => { e.preventDefault ();

        setSearch ( e.target.value );

        if ( e.target.value.length === 0 ) return setExpanded ( false );

        if ( expanded ) return;

        if ( ! expanded ) setExpanded ( true );

    },

    /**
     * 
     * @event updateData
     * 
     * @description Updates the data.
     * 
     * @param { Object } user - The user object.
     * 
     * @returns { void }
     * 
     */

    updateData = user => {

        setExpanded ( false );

        update ( { 
            
            ...state,  
        
            firstName: user.firstName,

            lastName: user.lastName,

            companyName: user.companyInformation,

            regNr: user.regNr,

            pvnRegNr: user.pvnRegNr,

            vatIncluded: user.showVat,

            bank: user.banks[0]?.name,

            bankSwift: user.banks[0]?.swift,

            bankIban: user.banks[0]?.number,

            mobilePhoneNr: user.mobilePhoneNr,

            email: user.email,

            deliveryPlanId: user.deliveryPlanId,

            city: user.addresses[1]?.city,

            postCode: user.addresses[1]?.postalCode,

            address: user.addresses[1]?.address,

            postalCode: user.addresses[1]?.postalCode,

            shippingNotes: user.addresses[1]?.comment,

            customerIdCode: user?.contractNumber
        
        } );

        setDataList ( [] );

    };

    /**
     * 
     * @event useEffect
     * 
     * @description Handles the search string.
     * 
     */

    useEffect ( () => { const 
        
        timer = setTimeout( () => {

            setSearchString ( search );
        
        }, 1000 );
      
        return () => clearTimeout ( timer );

    }, [ search ] );

    /**
     * 
     * @event useEffect
     * 
     * @description Handles the search string.
     * 
     */

    useEffect ( () => {

        if ( searchString.length > 0 ) { refetch ( { page: 1, search: searchString } ); } else { setDataList ( [] ); }

    }, [ searchString, refetch ] );

    /**
     *  
     * @type { Function } handleOutsideClick - Handles outside click events 
     *
     * @param { Object } event - Outside click event
     *  
     */

    useEffect ( () => { const 
        
        handleOutsideClick = ( event ) => {

            if ( detector.current && ! detector.current.contains ( event.target ) ) {

                setExpanded ( false );

            }

        };

        document.addEventListener ( "mousedown", handleOutsideClick );

        return () => document.removeEventListener ( "mousedown", handleOutsideClick );

    }, [] );


    console.log (dataList );

    const testing = ["company", "name", "phone", "email"].join ( ", ")

    /** @returns { JSX.Element } CustomerSearch */

    return (

        <Fieldset legend={ __ ( "customer_search" ) }>

            <div className={ styles.searchContainer } ref={ detector }>

                <Input 
                
                    type="text" 
                
                    name="customer_search" 

                    value={ search }

                    onChange={ onSearch }

                    onFocus={ () => { dataList?.adminClients?.items.length > 0 && setExpanded ( true ) } }
                
                    placeholder={ __ ( "customer_search" ) } 
                    
                />

                <div className={ styles.dropdown } aria-expanded={ expanded }>

                    { loading ? <div className={ styles.loader }><CenterLoader /></div>:

                        dataList?.adminClients?.items.length > 0 ? 
                        
                            dataList.adminClients.items.map ( ( item, index ) =>

                                <div key={ index } className={ styles.item } onClick={ () => updateData ( item ) }>

                                    { [ item?.companyName, item?.fullName, item?.mobilePhoneNr, item?.email, item?.regNr ].filter( Boolean ).join ( ", ") }

                                </div>

                            ) 
                        
                        : 
                        
                            <div className={ styles.noResults }>

                                { __ ( "no_results" ) }

                            </div>

                    }

                </div>

            </div>

        </Fieldset>

    );

};