// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Global_container__CDSaR > *:not(:last-child) {\n    margin-bottom: 1rem;\n}\n\n.Global_content__F\\+cRC > *:not(:last-child) {\n    margin-bottom: 0.25rem;\n}\n\n.Global_action__oBouY {\n    background-color: var(--container-color);\n    padding: 1rem;\n    border-radius: 0.3rem;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    gap: 1rem;\n    align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Workshop/Contents/Offdays/sections/Global.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,wCAAwC;IACxC,aAAa;IACb,qBAAqB;IACrB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,SAAS;IACT,mBAAmB;AACvB","sourcesContent":[".container > *:not(:last-child) {\n    margin-bottom: 1rem;\n}\n\n.content > *:not(:last-child) {\n    margin-bottom: 0.25rem;\n}\n\n.action {\n    background-color: var(--container-color);\n    padding: 1rem;\n    border-radius: 0.3rem;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    gap: 1rem;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Global_container__CDSaR",
	"content": "Global_content__F+cRC",
	"action": "Global_action__oBouY"
};
export default ___CSS_LOADER_EXPORT___;
