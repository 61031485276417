// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tabs_container__EIDMC {\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: wrap;\n\n    gap: 1rem;\n\n    align-items: center;\n\n}\n\n.Tabs_container__EIDMC > span {\n\n    background-color: var(--accent-background-color);\n\n    border-radius: 6px;\n\n    padding: 8px 16px;\n\n    color: var(--typography-color);\n\n    font-size: 14px;\n\n    cursor: pointer;\n\n}   \n\n.Tabs_container__EIDMC > span:hover {\n\n    background-color: var(--accent-color);\n\n    color: white;\n\n}\n\n.Tabs_active__664dx {\n\n    pointer-events: none;\n\n    background-color: var(--secondary-color)!important;\n\n    color: var(--invert-solid)!important;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Workshop/Components/Tabs/Tabs.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;;IAEb,mBAAmB;;IAEnB,eAAe;;IAEf,SAAS;;IAET,mBAAmB;;AAEvB;;AAEA;;IAEI,gDAAgD;;IAEhD,kBAAkB;;IAElB,iBAAiB;;IAEjB,8BAA8B;;IAE9B,eAAe;;IAEf,eAAe;;AAEnB;;AAEA;;IAEI,qCAAqC;;IAErC,YAAY;;AAEhB;;AAEA;;IAEI,oBAAoB;;IAEpB,kDAAkD;;IAElD,oCAAoC;;AAExC","sourcesContent":[".container {\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: wrap;\n\n    gap: 1rem;\n\n    align-items: center;\n\n}\n\n.container > span {\n\n    background-color: var(--accent-background-color);\n\n    border-radius: 6px;\n\n    padding: 8px 16px;\n\n    color: var(--typography-color);\n\n    font-size: 14px;\n\n    cursor: pointer;\n\n}   \n\n.container > span:hover {\n\n    background-color: var(--accent-color);\n\n    color: white;\n\n}\n\n.active {\n\n    pointer-events: none;\n\n    background-color: var(--secondary-color)!important;\n\n    color: var(--invert-solid)!important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Tabs_container__EIDMC",
	"active": "Tabs_active__664dx"
};
export default ___CSS_LOADER_EXPORT___;
