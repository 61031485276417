import styles from "./Title.module.css";


export default function WorkshopTitle ( { title = "Untitled", description, action } ) {


    return (

        <div className={ styles.container }>

            <div className={ styles.content }>

                <p className={ styles.title }>{ title }</p>

                { description && <p className={ styles.description }>{description}</p> }

            </div>

            {

                action &&

                <div className={ styles.action }>

                    { action }

                </div>

            }

        </div>

    );


};