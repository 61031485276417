/** @module                                 Router */

import { 
    
    Route, 
    
    createBrowserRouter, 
    
    createRoutesFromElements, 
    
    RouterProvider }                        from 'react-router-dom';

/** @module                                 Components */

import { Navigate }                         from 'react-router-dom';

/** @module                                 Pages */

import Authorization                        from '../Pages/Authorization';

import Dashboard                            from '../Pages/Dashboard';

import Dash                                 from '../Pages/Dash/Dash';

import Orders                               from '../Pages/Orders/Orders';

import EditOrder                            from '../Pages/Orders/Edit';

import CreateOrder                          from '../Pages/Orders/Create';

import StorefrontOrders                     from '../Pages/Orders/Storefront';

import ManagerOrders                        from '../Pages/Orders/Managers';

// import Offers                               from '../Pages/Orders/Offers';

import Montage                              from '../Pages/Montage/Montage';

import AddAppointment                       from '../Pages/Montage/Add';

import EditAppointment                      from '../Pages/Montage/Edit';

import AppointmentWorkhours                 from '../Pages/Montage/Workhours';

import Catalog                              from '../Pages/Products/Collection/Catalog';

import CreateProduct                        from '../Pages/Products/Create';

import UpdateProduct                        from '../Pages/Products/Edit';

import Search                               from '../Pages/Products/Search';

import Collection                           from '../Pages/Products/Collection';

import Tires                                from '../Pages/Products/Collection/Tires';

import Rims                                 from '../Pages/Products/Collection/Rims';

import Batteries                            from '../Pages/Products/Collection/Batteries';

import AddTire                              from '../Pages/Products/Add/Tire';

import Sale                                 from '../Pages/Products/Sale';

import Manufacturers                        from '../Pages/Shop/Manufacturers/Manufacturers';

import ManufacturerProducts                 from '../Pages/Shop/Manufacturers/Products';

import CreateManufacturer                   from '../Pages/Shop/Manufacturers/Create';

import UpdateManufacturer                   from '../Pages/Shop/Manufacturers/Edit';

import Import                               from '../Pages/Products/Import';

import Export                               from '../Pages/Products/Export';

import Suppliers                            from '../Pages/Shop/Vendors/Suppliers';

import CreateVendor                         from '../Pages/Shop/Vendors/Create';

import UpdateVendor                         from '../Pages/Shop/Vendors/Edit';

import Applications                         from '../Pages/Shop/Applications';

import Specifications                       from '../Pages/Shop/Specifications';

import AdditionalInfo                       from '../Pages/Shop/Additional_Information';

import Sizes                                from '../Pages/Shop/Sizes';

import Industries                           from '../Pages/Shop/Industries';

import Countries                            from '../Pages/Shop/Countries';

import Banks                                from '../Pages/Shop/Banks';

import Currencies                           from '../Pages/Shop/Currencies';

import DirectSuppliers                      from '../Pages/Shop/Direct_Suppliers';

import Delivery                             from '../Pages/Shop/Delivery';

import Languages                            from '../Pages/Shop/Languages';

import Clients                              from '../Pages/Clients/index.js';
import ClientsEdit                          from '../Pages/Clients/Edit/index.js';
import ClientsAdd                           from '../Pages/Clients/Add/index.js';


import Managers                             from '../Pages/Managers/Managers';

import EditManager                          from '../Pages/Managers/Edit';

//import BlockedManagers                      from '../Pages/Managers/Blocked';

import ManagerContacts                      from '../Pages/Managers/Contacts';

import AddManagers                          from '../Pages/Managers/Add';

import Pricing                              from '../Pages/Pricing/Pricing';

import Posts                                from '../Pages/Content/Posts/Posts';

import AddPosts                             from '../Pages/Content/Posts/Add';

import EditPost                             from '../Pages/Content/Posts/Edit';

import Services                             from '../Pages/Content/Services/Services';

import EditService                          from '../Pages/Content/Services/Edit';

import AddService                           from '../Pages/Content/Services/Add';

import GeneralSettings                      from '../Pages/Settings/General';

import TableSettings                        from '../Pages/Settings/Tables';

import { AuthContextProvider }              from '../Contexts/AuthContext';

import { TiresContextProvider }             from '../Contexts/Products/TiresContext';

import ProductsLayout                       from '../Pages/Products/Collection/Layout';

import Customers                            from '../Pages/Customers/Customers';

import EditFallback                         from '../Pages/Pages/EditFallback';

import DynamicPages                         from '../Pages/Pages/Pages';

import Pagelist                             from '../Pages/Pages/Pagelist';

import AddPage                              from '../Pages/Pages/Add';

import EditPage                             from '../Pages/Pages/Edit';

import ShopContacts                         from '../Pages/Contacts/Shop';

import Team                                 from '../Pages/Contacts/Team';

import AddMember                            from '../Pages/Contacts/Team/Add';

import EditMember                           from '../Pages/Contacts/Team/Edit';

import Markups                              from '../Pages/Shop/Markups/Markups';

import SeasonMarkup                         from '../Pages/Shop/Markups/Seasons';

import ManufacturerMarkup                   from '../Pages/Shop/Markups/Manufacturers';

import VendorMarkup                         from '../Pages/Shop/Markups/Vendors';

import GlobalMarkup                         from '../Pages/Shop/Markups/Global';

import ShopPages                            from '../Pages/Shop/Shop';

import Workshop                             from '../Pages/Workshop/Workshop.js';

/** @returns application routes */

const Routing = () => { 

    const router = createBrowserRouter ( createRoutesFromElements (

        <Route path="/" element={ <AuthContextProvider /> }>
    
            <Route index element={ <Authorization name="authorization" /> } />
    
            <Route path="app" element={ <Dashboard /> }>
    
                <Route path="dashboard" element={ <Dash name="dashboard" /> } />

                <Route path="montage">

                    <Route index element={ <Montage name="montage" /> } />

                    <Route path="add" element={ <AddAppointment name="add_appointment" /> } />

                    <Route path="edit/:appointmentId" element={ <EditAppointment name="edit_appointment" /> } />

                    <Route path="workhours" element={ <AppointmentWorkhours name="workhours" /> } />

                </Route>

                <Route path="workshop">

                    <Route index element={ <Workshop /> } />

                </Route>


                <Route path="clients">
                    <Route index element={<Clients name="clients" />} />

                    <Route path="preview/:clientId" element={ <ClientsEdit name="edit_clients" isPreview /> } />

                    <Route path="edit/:clientId" element={ <ClientsEdit name="edit_clients" /> } />
                    <Route path="edit" element={ <Navigate to="/app/clients" /> } />

                    <Route path="add" element={ <ClientsAdd name="add_clients" /> } />

                </Route>


                <Route path="prices" element={ <Pricing name="pricing" /> } />

                <Route path="contacts">

                    <Route index element={ <ShopContacts name="shop" /> } />

                    <Route path="team" element={ <Team name="team" /> } />

                    <Route path="team/add" element={ <AddMember name="add_new" /> } />

                    <Route path="team/edit/:memberId" element={ <EditMember name="edit" /> } />

                    <Route path="team/edit" element={ <Navigate to="/app/contacts/team" replace /> } />

                </Route>

                <Route path="settings">

                    <Route index element={ <GeneralSettings name="general_settings" /> } />

                    <Route path="tables" element={ <TableSettings name="table_settings" /> } />

                </Route>

                <Route path="orders">

                    <Route index element={ <Orders name="orders" /> } />

                    <Route path=":orderFilter" element={ <Orders name="storefront_orders" /> } />

                    <Route path="edit/:orderId" element={ <EditOrder name="edit_order" /> } />

                    <Route path="add" element={ <CreateOrder name="create_order" /> } />

                    <Route path="add/:checkout" element={ <CreateOrder name="create_order" /> } />

                </Route>

                <Route path="content">

                    <Route path="posts" element={ <Posts name="posts" /> } />

                    <Route path="posts/add" element={ <AddPosts name="add_post" /> } />

                    <Route path="posts/:language" element={ <Posts name="posts" /> } />

                    <Route path="posts/edit/:postId" element={ <EditPost name="edit_post" /> } />

                    <Route path="services" element={ <Services name="services" /> } />

                    <Route path="services/:language" element={ <Services name="services" /> } />

                    <Route path="services/edit/:serviceId" element={ <EditService name="edit_service" /> } />

                    <Route path="services/add" element={ <AddService name="add_service" /> } />

                </Route>

                <Route path="pages">

                    <Route index element={ <DynamicPages name="pages" /> } />

                    <Route path=":type" element={ <Pagelist name="pages" /> } />

                    <Route path=":type/:language" element={ <Pagelist name="pages" /> } />

                    <Route path=":type/:language/add" element={ <AddPage name="add_new" /> } />

                    <Route path=":type/:language/edit/:pageId" element={ <EditPage name="edit_page" /> } />

                    <Route path=":type/:language/edit" element={ <EditFallback /> } />

                </Route>

                <Route path="customers" element={ <Customers name="customers" /> } />

                <Route path="managers">

                    <Route path="active" element={ <Managers name="managers" /> } />

                    {/* <Route path="blocked" element={ <BlockedManagers name="blocked_managers" /> } /> */}

                    <Route path="contacts" element={ <ManagerContacts name="manager_contacts" /> } />

                    <Route path="add" element={ <AddManagers name="add_managers" /> } />

                    <Route path="edit/:managerId" element={ <EditManager name="edit_manager" /> } />

                </Route>

                <Route path="products" element={ <ProductsLayout name="products" /> }>

                    <Route path=":productType" element={ <Catalog /> } />

                </Route>

                <Route path="products/:productType/create" element={ <CreateProduct name="create_product" /> } />

                <Route path="products/edit/:productId" element={ <UpdateProduct name="edit_product" /> } />

                <Route path="products/import" element={ <Import name="import" /> } />

                <Route path="products/import/:productType" element={ <Import name="import" /> } />

                <Route path="shop">

                    <Route index element={ <ShopPages name="shop" /> } />

                    <Route path="suppliers" element={ <Suppliers name="suppliers" /> } />

                    <Route path="suppliers/create" element={ <CreateVendor name="create_supplier" /> } />

                    <Route path="suppliers/edit/:vendorId" element={ <UpdateVendor name="update_supplier" /> } />

                    <Route path="manufacturers" element={ <Manufacturers name="manufacturers" /> } />

                    <Route path="manufacturers/create" element={ <CreateManufacturer name="create_manufacturer" /> } />

                    <Route path="manufacturers/:manufacturerId/products" element={ <ManufacturerProducts name="products" /> } />

                    <Route path="/app/shop/manufacturers/edit/:manufacturerId" element={ <UpdateManufacturer name="edit_manufacturer" /> } />

                    <Route path="markups" element={ <Markups name="markups" /> }>

                        <Route path="global/:discountGroup" element={ <GlobalMarkup name="global_markup" /> } />

                        <Route path="manufacturers/:discountGroup" element={ <ManufacturerMarkup name="manufacturer_markup" /> } />

                        <Route path="vendors/:discountGroup" element={ <VendorMarkup name="vendor_markup" /> } />

                        <Route path="seasons/:discountGroup" element={ <SeasonMarkup name="season_markup" /> } />

                    </Route>
                    
                    <Route path="applications" element={ <Applications name="applications" /> } />

                    <Route path="specifications" element={ <Specifications name="spefications" /> } />

                    <Route path="additional-information" element={ <AdditionalInfo name="additional_information" /> } />

                    <Route path="sizes" element={ <Sizes name="sizes" /> } />

                    <Route path="industries" element={ <Industries name="industries" /> } />

                    <Route path="countries" element={ <Countries name="countries" /> } />

                    <Route path="banks" element={ <Banks name="banks" /> } />

                    <Route path="currencies" element={ <Currencies name="currencies" /> } />

                    <Route path="direct-suppliers" element={ <DirectSuppliers name="direct_suppliers" /> } />

                    <Route path="delivery" element={ <Delivery name="delivery" /> } />

                    <Route path="languages" element={ <Languages name="languages" /> } />

                </Route>

            </Route>
    
        </Route>
    
    ) );

    /** @returns */

    return ( <RouterProvider router={ router } /> )

}

/** @exports Routing */

export default Routing;