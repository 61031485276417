import styles from './ProductsName.module.css';

import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { GiSpikeball } from "react-icons/gi";

import { IoPrismSharp } from "react-icons/io5";

/**
 * 
 * @exports ProductsName
 * 
 * @description The products name component 
 * 
 * @param { Object } props - The component props
 * 
 * @param { Object } props.product - The product object
 * 
 * @returns { React.ReactElement }
 * 
 * @example <ProductsName product={ product } />
 * 
 */

export default function ProductsName ( { product } ) {

    const 

    { t : __ } = useTranslation (),

    properties = {

        tire: `${ product.width } | ${ product.height } | R${ parseInt ( product.diameter ) }${ product?.isCTire ? "C" : "" } | ${ product?.loadIndex }${ product?.speedRating?.toUpperCase () } | DOT: ${ product?.year || "-" }`,

        rim: `${ product?.width || "-" }J | R${ parseInt ( product?.diameter ) || "-" } | ${ product?.pitchCircleDiameter || "-" } | ET${ product?.offset || "-" } | CB${ product?.centreBore || "-" } | ${ product?.color || "-"}`,

        battery: `${ product.voltage } V | ${ product.current } A | ${ product.capacity } Ah`,

    };

    /**
     * 
     * @description
     * 
     * Return the products name component
     * 
     * @returns { React.ReactElement }
     * 
     */

    return (

        <td>

            <Link to={ `/app/products/edit/${ product?.id }` }>

                <div className={ styles.content }>

                    <div className={ styles.id }>

                        ID: { product?.id || "-" } ({ __( product?.productType || "Unknown" ) })

                    </div>

                    <div className={ styles.manufacturer }>

                        { product?.manufacturer?.name || "-" }

                    </div>

                    <div className={ styles.model }>

                        { product?.model || "-" }

                    </div>

                    { // Display properties if product type is known and properties are available
                    
                        product?.productType && 

                        <div className={ styles.properties }>

                            { properties[ product.productType ] }

                        </div>
                    
                    }

                    { // Display specifications if product type is tire
                    
                        product?.productType === 'tire' && <ProductSpecifications product={ product } /> 
                        
                    }

                </div>

            </Link>

        </td>

    );

};

/**
 * 
 * @function ProductSpecifications
 * 
 * @param { Object } props - Component props
 * 
 * @param { Object } props.product - The product object
 * 
 * @returns { React.ReactElement }
 * 
 * @description The product specifications component
 * 
 * @example <ProductSpecifications product={ product } />
 * 
 */

function ProductSpecifications ( { product } ) {

    /**
     * 
     * @constant { Array } specifications - The product specifications
     * 
     * @description The product specifications array (season, runFlat, xl, OE, studs)
     * 
     */

    const 
    
    specifications = [ 
        
        product?.season?.toUpperCase () || null, 
        
        product?.runFlat ? "RFT" : null, 
         
        product?.xl ? "XL" : null,

        product?.specifications?.length ? "OE" : null,

        product?.studs === "yes" ? <GiSpikeball /> : product?.studs === "possible" ? <IoPrismSharp /> : null
    
    ];

    /**
     * 
     * @description 
     * 
     * Return the product specifications component
     * 
     * @returns { React.ReactElement }
     * 
     */

    return (

        <div className={ styles.specifications }>

            {

                specifications.map ( ( specification, index ) => 
                
                    {

                        if ( ! specification ) return null;

                        return (

                            <div key={ index } className={ styles.specification }>

                                { specification }

                            </div>

                        );

                    }
                
                )

            }

        </div>

    );

}