import { gql } from '@apollo/client';

export const UPDATE_WORKHOURS = gql`mutation UpdateWorkhours (

    $id: Int!
    $startTimeMonday: String!
    $endTimeMonday: String!
    $startTimeTuesday:  String!
    $endTimeTuesday:  String!
    $startTimeWednesday:  String!
    $endTimeWednesday:  String!
    $startTimeThursday:  String!
    $endTimeThursday:  String!
    $startTimeFriday:  String!
    $endTimeFriday:  String!
    $startTimeSaturday:  String!
    $endTimeSaturday:  String!
    $startTimeSunday:  String!
    $endTimeSunday: String!

) {

    adminUpdateWorkHours (

        input: {

            id: $id

            workHoursInput: {

                startTimeMonday: $startTimeMonday
                endTimeMonday: $endTimeMonday
                startTimeTuesday: $startTimeTuesday
                endTimeTuesday: $endTimeTuesday
                startTimeWednesday: $startTimeWednesday
                endTimeWednesday: $endTimeWednesday
                startTimeThursday: $startTimeThursday
                endTimeThursday: $endTimeThursday
                startTimeFriday: $startTimeFriday
                endTimeFriday: $endTimeFriday
                startTimeSaturday: $startTimeSaturday
                endTimeSaturday: $endTimeSaturday
                startTimeSunday: $startTimeSunday
                endTimeSunday: $endTimeSunday

            }

        }

    ) {

        workHour {

            id

        }

    }

}`;

export const UPDATE_BOX = gql`mutation UpdateBox (

    $id: ID!
    $boxName: String
    $active: Boolean

) {

    adminUpdateServiceBox (

        input: {

            id: $id

            serviceBoxInput: {

                boxName: $boxName
                active: $active

            }

        }

    ) {

        serviceBox {

            id

        }

    }

}`;


export const DELETE_BOX = gql`mutation DeleteBox (

    $id: ID!

) {

    adminDestroyServiceBox (

        input: {

            id: $id

        }

    ) {

        serviceBox {

            id

        }

    }

}`;

export const CREATE_BOX = gql`mutation CreateBox (

    $boxName: String
    $active: Boolean

) {

    adminCreateServiceBox (

        input: {

            serviceBoxInput: {

                boxName: $boxName
                active: $active

            }

        }

    ) {

        clientMutationId

    }

}`;

export const CREATE_OFFDAY = gql`mutation CreateOffDay (

    $offDate: ISO8601Date!

) {

    adminCreateOffDay (

        input: {

            offDayInput: {

                offDate: $offDate

            }

        }

    ) {

        offDay {

            id

        }

    }

}`;


export const DELETE_OFFDAY = gql`mutation DeleteOffDay (

    $id: ID!

) {

    adminDestroyOffDay (

        input: {

            id: $id

        }

    ) {

        offDay {

            id

        }

    }

}`;


export const CREATE_SERVICE_BOX_OFFDAY = gql`mutation CreateServiceBoxOffDay (

        $serviceBoxId: Int!
        $date: ISO8601DateTime!

) {

    adminCreateSerivceBoxOfDay (

        input: {

            serivceBoxOffDayInput: {

                date: $date
                serviceBoxId: $serviceBoxId

            }

        }

    ) {

        clientMutationId

    }

}`;

export const DELETE_SERVICE_BOX_OFFDAY = gql`mutation DeleteServiceBoxOffDay (

    $serviceBoxOffDayId: ID!

) {

    adminDestroySerivceBoxOfDay (

        input: {

            serviceBoxOffDayId: $serviceBoxOffDayId

        }

    ) {

        clientMutationId

    }

}`;