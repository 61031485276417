import styles from './GlobalItem.module.css';

import { useMutation } from '@apollo/client';

import { DELETE_OFFDAY } from '../../../../../../Mutations/Workshop';

import Button from '../../../../Global/Forms/Button';


export default function GlobalWorkshopItem ( { data, refetch } ) {


    const [ removeOffday, { loading } ] = useMutation ( DELETE_OFFDAY );
    
    const handleRemove = async () => {

        try {

            await removeOffday ({ variables: { id: data?.id } });

            refetch ();

        } catch ( error ) {

            console.log ( error );

        }

    };


    return (

        <div className={ styles.container }>
            
            <div className={ styles.date }>

                { new Date(data?.offDate).toLocaleDateString('en-GB') }

            </div>

            <Button onClick={ handleRemove } text="Delete" className={ styles.delete } disabled={ loading } />

        </div>

    );


};