import styles from "./WorkingHours.module.css";
import { useState } from "react";
import { useMutation } from '@apollo/client';
import { UPDATE_WORKHOURS } from "../../../../../Mutations/Workshop";
import WorkingHoursItem from "./Item";
import Button from "../../../Global/Forms/Button";

const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export default function WorkingHoursSection({ data, loading, error }) {
    const initialState = DAYS.reduce((acc, day) => ({
        ...acc,
        [`startTime${day}`]: data?.[`startTime${day}`]?.replace("-", ":") || "00:00",
        [`endTime${day}`]: data?.[`endTime${day}`]?.replace("-", ":") || "00:00",
        [`${day.toLowerCase()}Enabled`]: data?.[`startTime${day}`] === "00-00" && data?.[`endTime${day}`] === "00-00"
    }), {});

    const [workingHours, setWorkingHours] = useState(initialState);
    const [updateWorkHours, { loading: loading_mutation }] = useMutation(UPDATE_WORKHOURS);
    const [message, setMessage] = useState({ type: null, text: null });

    const handleSubmit = async () => {
        const workingHoursData = {
            id: parseInt(data?.id || 0),
            ...DAYS.reduce((acc, day) => ({
                ...acc,
                [`startTime${day}`]: workingHours[`startTime${day}`].replace(":", "-"),
                [`endTime${day}`]: workingHours[`endTime${day}`].replace(":", "-")
            }), {})
        };

        try {
            await updateWorkHours({ variables: workingHoursData });
            setMessage({ type: 'success', text: 'Working hours updated successfully!' });
            setTimeout(() => setMessage({ type: null, text: null }), 3000);
        } catch (error) {
            setMessage({ type: 'error', text: 'Error updating work hours. Please try again.' });
        }
    };

    return (
        <div className={styles.section}>
            {message.text && (
                <div className={styles[message.type]}>{message.text}</div>
            )}
            
            <div className={styles.content}>
                {DAYS.map(day => (
                    <WorkingHoursItem
                        key={day}
                        name={day}
                        start={workingHours[`startTime${day}`]}
                        end={workingHours[`endTime${day}`]}
                        status={workingHours[`${day.toLowerCase()}Enabled`]}
                        loading={loading}
                        loading_save={loading_mutation}
                        update={setWorkingHours}
                    />
                ))}
            </div>

            <div className={styles.actions}>
                <Button 
                    text="Save Changes"
                    action={handleSubmit}
                    disabled={loading || loading_mutation}
                />
            </div>
        </div>
    );
}