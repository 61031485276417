// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Boxes_container__oi\\+iI > *:not( :last-child ) {\n\n    margin-bottom: 1rem;\n\n}\n\n.Boxes_content__C-gCv > *:not( :last-child ) {\n\n    margin-bottom: 0.25rem;\n\n}\n\n.Boxes_action__\\+1MxB {\n\n    background-color: var(--container-color);\n\n    padding: 1rem;\n\n    border-radius: 0.3rem;\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: wrap;\n\n    gap: 1rem;\n\n    align-items: center;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Workshop/Contents/Boxes/Boxes.module.css"],"names":[],"mappings":"AAAA;;IAEI,mBAAmB;;AAEvB;;AAEA;;IAEI,sBAAsB;;AAE1B;;AAEA;;IAEI,wCAAwC;;IAExC,aAAa;;IAEb,qBAAqB;;IAErB,aAAa;;IAEb,mBAAmB;;IAEnB,eAAe;;IAEf,SAAS;;IAET,mBAAmB;;AAEvB","sourcesContent":[".container > *:not( :last-child ) {\n\n    margin-bottom: 1rem;\n\n}\n\n.content > *:not( :last-child ) {\n\n    margin-bottom: 0.25rem;\n\n}\n\n.action {\n\n    background-color: var(--container-color);\n\n    padding: 1rem;\n\n    border-radius: 0.3rem;\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: wrap;\n\n    gap: 1rem;\n\n    align-items: center;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Boxes_container__oi+iI",
	"content": "Boxes_content__C-gCv",
	"action": "Boxes_action__+1MxB"
};
export default ___CSS_LOADER_EXPORT___;
