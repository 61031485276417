import styles from "./IndividualItem.module.css";

import { useMutation } from "@apollo/client";

import { DELETE_SERVICE_BOX_OFFDAY } from "../../../../../../Mutations/Workshop";

import Button from "../../../../Global/Forms/Button";


export default function IndividualWorkshopItem ( { data, refetch, serviceBox } ) {


    const [ removeOffday, { loading } ] = useMutation ( DELETE_SERVICE_BOX_OFFDAY );
    
    const handleRemove = async () => {

        try {

            await removeOffday ({ variables: { serviceBoxOffDayId: data?.id } });

            refetch ();

        } catch ( error ) {

            console.log ( error );

        }

    };


    return (

        <div className={ styles.container }>
            
            <div className={ styles.date }>

                <span className={ styles.serviceBoxName }>{ serviceBox }</span>

                { new Date(data?.date).toLocaleDateString('en-GB') }

            </div>

            <Button onClick={ handleRemove } text="Delete" className={ styles.delete } disabled={ loading } />

        </div>

    );


};