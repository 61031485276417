import styles from "./Tabs.module.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

export default function WorkshopTabs() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const currentTab = new URLSearchParams(location.search).get('tab');
    
    const [activeTab, setActiveTab] = useState(currentTab || 'working-hours');

    const tabs = [

        { name: 'working-hours', label: t ( 'Working Hours' ) },

        { name: 'boxes', label: t ( 'Boxes' ) },

        { name: 'off-days', label: t ( 'Off Days' ) }

    ];

    useEffect(() => {
        if (!currentTab) {
            navigate('/app/workshop?tab=working-hours', { replace: true });
        } else {
            setActiveTab(currentTab);
        }
    }, [currentTab, navigate]);

    return (

        <div className={ styles.container }>

            {

                tabs.map((tab, index) => 

                    <span key={ index } className={ activeTab === tab.name ? styles.active : '' } onClick={ () => navigate(`/app/workshop?tab=${ tab.name}`) }>{ tab.label }</span>
                
                )
            }

        </div>

    );
};
