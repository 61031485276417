import styles from "./Toggle.module.css";

import { useId } from "react";

/**
 * 
 * @exports Toggle
 * 
 * @description This component is responsible for rendering a toggle input.
 * 
 * @param { Object } props
 * 
 * @param { String } props.id
 * 
 * @param { String } props.name
 * 
 * @param { String } props.className
 * 
 * @param { String } props.classNameGroup
 * 
 * @param { String } props.label
 * 
 * @example <Toggle />
 * 
 * @returns { JSX.Element } Toggle
 * 
 */

export default function Toggle ( {  id = "", name = "", className = "default", classNameGroup = "default", label = "", ...props } ) {

    const 

    idValue = useId ();

    /**
     * 
     * @description 
     * 
     * Render the toggle input component with the input data and label if provided.
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <div className={ [ styles.container, classNameGroup ].join ( " " ) }>

            <input 
                
                type="checkbox"  

                id={ idValue || name }

                name={ idValue || name }
                
                className={ styles.input } 
                
                { ...props }

            />

            <label htmlFor={ idValue || name } className={ styles.content }>

                <span className={ styles.toggle }></span>

                <span className={ styles.label }>

                    { label && label }

                    { props.required && <span className={ styles.required }>*</span> }

                </span>

            </label>

        </div>

    );

};