import { forwardRef } from "react";

import { BsCheck } from "react-icons/bs";

const 

Checkbox = forwardRef ( ( { 
    
    id = "default", 
    
    text = "Checkbox", 

    value = false,

    state = false,

    action = () => {},

    hidelabel = false, 

    styling = "default"

}, ref ) => {

    /** @returns */

    return ( <label htmlFor={ id } className={ `checkbox ${ styling }` }>

        <input type="checkbox" id={ id } name={ id } defaultChecked={ state } value={ value } ref={ ref } onChange={ e => action ( e ) } />

        <span className="icon">

            <span className="selected">

                <BsCheck />

            </span>

        </span>

        { hidelabel ? "" : <span className="name">{ text }</span> }

    </label> );

} );

/** @exports Checkbox */
 
export default Checkbox;

