import styles from "./WorkingHours.module.css";

import { useQuery } from "@apollo/client";

import { GET_WORKSHOP_HOURS } from "../../../../../Queries/Workshop";

import ComponentLoader from "../../../Loaders/ComponentLoader";

import WorkingHoursSection from "./Section";


export default function WorkshopHours () {


    const { loading, error, data } = useQuery(GET_WORKSHOP_HOURS, { fetchPolicy: "network-only" });

    if (loading) return <ComponentLoader />;


    return (


        <div className={ styles.container }>

            {

                data?.adminWorkingHours?.length ? 
                
                data.adminWorkingHours.map( ( hours, index ) => 

                    <WorkingHoursSection data={ hours } loading={ loading } error={ error } key={ hours?.id || index } />
                
                )
                
                : "No working hours found, contact developer."

            }

        </div>


    );


};