// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Box_container__ucdP4 {\n\n    background-color: var(--container-color);\n\n    padding: 1rem;\n\n    border-radius: 0.3rem;\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: wrap;\n\n    gap: 1rem;\n\n    align-items: center;\n\n}\n\n.Box_action__L8SzQ {\n\n    flex: 1 1;\n\n    display: flex;\n\n    justify-content: flex-end;\n\n    gap: 1rem;\n\n}\n\n.Box_delete__L7kJZ {\n\n    background-color: #F44336;\n\n}\n\n.Box_delete__L7kJZ:hover {\n\n    background-color: #b71c1c;\n\n}\n\n.Box_toggle__MxTPT {\n\n    display: inline-block;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Workshop/Contents/Boxes/Box.module.css"],"names":[],"mappings":"AAAA;;IAEI,wCAAwC;;IAExC,aAAa;;IAEb,qBAAqB;;IAErB,aAAa;;IAEb,mBAAmB;;IAEnB,eAAe;;IAEf,SAAS;;IAET,mBAAmB;;AAEvB;;AAEA;;IAEI,SAAO;;IAEP,aAAa;;IAEb,yBAAyB;;IAEzB,SAAS;;AAEb;;AAEA;;IAEI,yBAAyB;;AAE7B;;AAEA;;IAEI,yBAAyB;;AAE7B;;AAEA;;IAEI,qBAAqB;;AAEzB","sourcesContent":[".container {\n\n    background-color: var(--container-color);\n\n    padding: 1rem;\n\n    border-radius: 0.3rem;\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: wrap;\n\n    gap: 1rem;\n\n    align-items: center;\n\n}\n\n.action {\n\n    flex: 1;\n\n    display: flex;\n\n    justify-content: flex-end;\n\n    gap: 1rem;\n\n}\n\n.delete {\n\n    background-color: #F44336;\n\n}\n\n.delete:hover {\n\n    background-color: #b71c1c;\n\n}\n\n.toggle {\n\n    display: inline-block;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Box_container__ucdP4",
	"action": "Box_action__L8SzQ",
	"delete": "Box_delete__L7kJZ",
	"toggle": "Box_toggle__MxTPT"
};
export default ___CSS_LOADER_EXPORT___;
