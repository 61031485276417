/** @module         Apollo */

import { gql }      from "@apollo/client";

const 

/** @name APPOINTMENTS */

APPOINTMENTS = gql`query

    fetchAppointments (

        $stringSearch: String,

        $after: String,

        $before: String,

        $first: Int,

        $last: Int

    ) { 
        
        adminAppointments (

            first: $first

            last: $last

            before: $before

            after: $after

            stringSearch: $stringSearch

        ) {

            edges {

                node {

                    autoBrand

                    autoModel

                    autoNumber

                    destroyUuid

                    diskDiameter

                    email

                    endTime

                    fullName

                    id

                    notes

                    phoneNumber

                    serviceBox {

                        boxName
            
                    }

                    startTime

                    tiresBought

                    tiresBoughtNotes

                    tiresStored

                    tiresStoredNotes

                    transportType
                    
                }
                    
            }

            pageInfo { 
            
                hasNextPage 
                
                hasPreviousPage 
                
                startCursor 
                
                endCursor 
            
            }

        } 
    
    }`,

/** @name APPOINTMENT_PREVIEW */

APPOINTMENT_PREVIEW = gql`query fetchAppointmentPreview ( $id: ID! ) {

    adminAppointment ( id: $id ) {

        autoBrand

        autoModel

        autoNumber

        diskDiameter

        email

        endTime

        fullName

        notes

        phoneNumber

        serviceBox {

            boxName

        }

        startTime

        tiresBought

        tiresBoughtNotes

        tiresStored

        tiresStoredNotes

        transportType

    }

}`,

/** @name APPOINTMENT */

APPOINTMENT = gql`query fetchAppointment ( $id: ID! ) {

    adminAppointment ( id: $id ) {

        autoBrand

        autoModel

        autoNumber

        destroyUuid

        diskDiameter

        email

        endTime

        fullName

        id

        notes

        phoneNumber

        serviceBox {

            boxName

        }

        startTime

        tiresBought

        tiresBoughtNotes

        tiresStored

        tiresStoredNotes

        transportType

    }

}`,

/** @name APPOINTMENT_LIST */

APPOINTMENT_LIST = gql`query fetchAppointmentList ( 
    
    $year: Int!

    $month: Int!

    $day: Int!

    $transportType: String! 
    
) {

    appointmentsForDay ( 
        
        year: $year

        month: $month

        day: $day

        transportType: $transportType
        
    ) {

        day
    
		endHour
    
        endMinute
        
        fullEndTime
        
        fullStartTime
        
        month
        
        serviceBox1Busy
        
        serviceBox2Busy
        
        serviceBoxTranslation
        
        startHour
        
        startMinute
        
        status
        
        statusTranslation
        
        year

    }

}`;

/** @exports Queries */

export {

    APPOINTMENTS,

    APPOINTMENT,

    APPOINTMENT_PREVIEW,

    APPOINTMENT_LIST

}